<template>
  <!-- popup_wrap -->
  <div
    class="popup_wrap bg_white"
    style="width:400px; height:350px;"
  >
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <!-- popup_cont -->
    <div class="popup_cont">
      <div
        class="circle_icon notice"
        style="margin:30px auto 0;"
      />

      <p class="msg text_center color_blue font_16 font_medium mt15">
        <!-- 선택하신 모선은 공동운항 모선으로<br/>UNDER DECK 선적 개런티가 불가합니다. -->
        <span
          v-if="parentInfo.kmdVslCheck === 'N'"
          v-html="$t('msg.NEWB010P140.001')"
        />
        <!-- 선택하신 모선은 KMTC 모선으로<br/>기본적으로 UNDER DECK 선적 해드리고 있으나<br/>상황에 따라 UNDER DECK 선적이 불가능한 경우가 있습니다. -->
        <span
          v-else
          v-html="$t('msg.NEWB010P140.003')"
        />
      </p>

      <p class="msg text_center mt15">
        <!-- 따라서 하기  LOI DOWNLOAD를 통해 양식을 다운 받은 뒤,<br/>내용 입력 및 B/L SHIPPER의 직인/명판 득하여<br/>스캔본 업로드 부탁드립니다. -->
        <span
          v-if="parentInfo.kmdVslCheck === 'N'"
          v-html="$t('msg.NEWB010P140.002')"
        />
        <!-- UNDER DECK 선적이 불가능할 경우<br/>별도 안내 드리도록 하겠습니다. (ON DECK LOI 필요) -->
        <span
          v-else
          v-html="$t('msg.NEWB010P140.004')"
        />
      </p>

      <div
        v-if="parentInfo.kmdVslCheck === 'N'"
        class="mt20 text_center"
      >
        <a
          class="button blue md"
          href="#"
          @click.prevent="onDeckLoiDownload()"
        >
          <span class="btn_icon download" />
          {{ $t('msg.NEWB010P140.005') }}
        </a><!-- LOI DOWNLOAD -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import trans from '@/api/rest/trans/trans'

export default {
  name: 'OogLoiPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return { kmdVslCheck: 'N' }
      }
    }
  },
  data () {
    return {
      // 고정값
      fileInfo: {
        messageIdSeq: 'CKR100',
        fileId: '717',
        fileName: 'ON DECK LOI.xls'
      }
    }
  },
  methods: {
    onDeckLoiDownload () {
      const formData = this.fileInfo

      trans.onDeckLoiDownload(formData)
        .then(response => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //IE에서 동작
            window.navigator.msSaveOrOpenBlob(response.data, formData.fileName)
          } else {
            // console.log(response)
            //CHROME에서 동작
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            link.href = url
            link.setAttribute('download', formData.fileName)
            link.click()
            link.remove()

            window.URL.revokeObjectURL(url)
          }
        })
    }
  }
}
</script>
